<template>
  <div>
    <v-card class="grey lighten-5 pa-3 pa-sm-10  rounded-lg text-center" flat @click="dialog = true">
      <div><v-icon dark color="primary">mdi-plus</v-icon></div>
      <div class="text-subtitle-1 font-weight-bold primary--text">Order Virtual Naira Card</div>
    </v-card>
    <v-dialog v-model="dialog" width="550">
      <v-card class="form-colored-bg pa-8 px-16">
        <div class="text-center">
          <h4>Order a Virtual Card</h4>
        </div>
        <v-form v-model="valid" class="mt-10" lazy-validation ref="form">
          <p class="label">Card Name</p>
          <v-text-field
            outlined
            placeholder="Enter full name"
            v-model="form.name"
            required
            :rules="[(v) => !!v || 'Full name is required']"
          ></v-text-field>

          <p class="label">Select Currency</p>
          <v-select
            outlined
            placeholder="Select"
            v-model="form.currency"
            required :items="['Dollar', 'Naira']"
            :rules="[(v) => !!v || 'This field  is required']"
          ></v-select>
           <p class="label">Select Card Type</p>
          <v-select
            outlined
            placeholder="Select"
            v-model="form.card_type"
            required :items="['Mastercard', 'Visa', 'Verve']"
            :rules="[(v) => !!v || 'This field  is required']"
          ></v-select>

          <p class="label">How much would you like to fund card ()?</p>
          <v-text-field
            outlined
            placeholder=""
            v-model="form.amount"
            required prefix="$"
            :rules="[(v) => !!v || 'This field is required']"
          ></v-text-field>

          <p class="label">Choose Address (optional)</p>
          <v-text-field
            outlined
            placeholder=""
            v-model="form.address"
            required :rules="[v => !!v || 'This field is required']"

          ></v-text-field>



          <v-btn class="primary" :loading="loading" @click="$refs.form.validate() ? orderCard() : null" block x-large>Order Card</v-btn>
          <div class="text-center">
            <!-- <small class="footnote"
              >How much would you like to fund card ()?</small
            > -->

          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      form: {},
    }
  },
  methods: {
    async orderCard() {
      this.loading = true
      await this.$store
        .dispatch('cards/ordervirtualcard', this.form)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = this.dialog = false
          this.$refs.form.reset()
        }).catch(error => {
          console.log(error)
        })
    },
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  mounted() {
    this.form.name = this.user.name

  },
}
</script>
<style lang="scss" scoped>
small.footnote {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5c5a5a;

}
</style>
