<template>
  <div>
    <v-card class="grey lighten-5 pa-3 pa-sm-10  rounded-lg text-center" flat @click="dialog = true">
      <div><v-icon dark color="primary">mdi-plus</v-icon></div>
      <div class="text-subtitle-1 font-weight-bold primary--text">Order Physical Naira Card</div>
    </v-card>
          
    <v-dialog v-model="dialog" width="550">
      <v-card class="form-colored-bg pa-8 px-16">
        <div class="text-center">
          <h4>Order a Physical Card</h4>
        </div>
        <v-form v-model="valid" class="mt-10" lazy-validation ref="form">
          <p class="label">Card Name</p>
          <v-text-field
            outlined
            placeholder="Enter full name"
            v-model="form.name"
            required
            :rules="[(v) => !!v || 'Full name is required']"
          ></v-text-field>

          <p class="label">Phone Number</p>
          <v-text-field
            outlined
            placeholder="Phone Number"
            v-model="form.phone_number"
            required
            :rules="[(v) => !!v || 'Phone  is required']"
          ></v-text-field>

          <p class="label">Street Address</p>
          <v-text-field
            outlined
            placeholder="Street address"
            v-model="form.address"
            required
            :rules="[(v) => !!v || 'Address is required']"
          ></v-text-field>

          <p class="label">Select State</p>
          <v-select
            outlined
            @change="getlga(form.state)"
            item-text="name"
            item-value="name"
            placeholder="State"
            v-model="form.state"
            :items="states"
            required
            :rules="[(v) => !!v || 'State is required']"
          ></v-select>

          <p class="label">Select LGA</p>
          <v-select
            outlined
            placeholder="LGA"
            v-model="form.lga"
            item-text="name"
            item-value="name"
            :items="lgas"
            required
            :rules="[(v) => !!v || 'LGA is required']"
          ></v-select>

          <v-btn
            class="primary"
            :loading="loading"
            @click="$refs.form.validate() ? orderCard() : null"
            block
            x-large
            >Order Card</v-btn
          >
          <div class="text-center">
            <small class="footnote"
              >You will be charged ₦3,000.00 for a card and you'll be required
              to cover the cost of delivery to any location outside
              Abuja.</small
            >
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { states } from '@/util/statelga'
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      loading: false,
      form: {},
      states: states,
      lgas: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
  },
  methods: {
    getlga(name) {
      let obj = this.states.filter((item) => item.name === name)
      if (obj.length > 0) {
        this.lgas = obj[0].locals
      }
    },
    async orderCard() {
      this.loading = true
      await this.$store
        .dispatch('cards/orderphysical', this.form)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = this.dialog = false
          this.$refs.form.reset()
        }).catch(error => {
          console.log(error)
        })
    },
  },
  mounted() {
    this.form.name = this.user.name
    this.form.phone_number = this.user.phone
  },
}
</script>
<style lang="scss" scoped>
small.footnote {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #5c5a5a;
}
</style>
