<template>
  <v-container class="px-5 mb-10">
    <v-row>
      <v-col cols="12" md="4">
        <div class="white border rounded-lg pa-4">
          <request-physical-card />
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="white border rounded-lg pa-4">
          <request-virtual-card />
        </div>
      </v-col>
      <v-col class="mt-10">
        <v-card class="debit-card py-5 px-8 rounded-lg" flat>
          <v-row>
            <v-col cols="6">
              <v-icon color="white" size="60">credit_card </v-icon>
            </v-col>
            <v-col class="text-right">
              <p>
                <img src="@/static/assets/mastercard.svg" width="50px" alt="" />
                mastercard
              </p>
            </v-col>
          </v-row>
          <div class="text-center">
            <div class="card-number text-overline text-center my-3" style="font-size: 25px !important">
              3129&emsp;****&emsp;****&emsp;4782
            </div>
          </div>
          <v-row class="">
            <v-col cols="9">
              <p class="small mb-0 mt-5">CARD HOLDER</p>
              <div class="text-overline white--text">{{ user.name ? user.name.split(' ').slice(0, -1).join(' ') + " " + user.name.split(' ').slice(-1).join(' ') : '' }}</div>
            </v-col>
            <v-col>
              <p class="small mb-0 mt-5">EXPIRY</p>
              <div class="text-overline white--text">09/21</div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    

    <v-row justify="center" align="center" class="mt-10">
      
    </v-row>
  </v-container>
</template>
<script>
import RequestPhysicalCard from '@/components/cards/RequestPhysicalCard.vue'
import RequestVirtualCard from '@/components/cards/RequestVirtualCard'
import { mapGetters } from 'vuex'

export default {
  layout: 'dashboard',
  transition: 'default',
  components: { RequestPhysicalCard, RequestVirtualCard },
  data() {
    return {
      myCards: false
    }
  },

  computed: {
    ...mapGetters("auth", ["user", "loan_balance"]),
  }
}
</script>
<style lang="scss" scoped>
.savings-card {
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #171b70;
  }
}
.debit-card {
  width: 440px;

 /* background: linear-gradient(
    308.06deg,
    #1b1f6a 18.46%,
    rgba(51, 55, 143, 0.901706) 39.66%,
    rgba(49, 53, 141, 0.829425) 48.63%,
    rgba(47, 51, 139, 0.763331) 60.15%,
    rgba(42, 47, 134, 0.622896) 69.7%,
    rgba(39, 43, 129, 0.503852) 78.52%,
    rgba(37, 41, 127, 0.448794) 80.05%,
    rgba(35, 39, 126, 0.396328) 83.11%,
    rgba(33, 37, 122, 0.307506) 90.67%,
    rgba(27, 31, 116, 0.137381) 96.78%,
    #8c90d3 99.99%,
    rgba(23, 27, 112, 0) 100%,
    rgba(28, 32, 117, 0.153216) 100%
  );
  */
  background: #061524;
 // border: 1px solid #e0e1ff;
  // box-sizing: border-box;
  border-radius: 5px;
  .card-number {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 140.44%;
    color: #ffffff;
  }
  p.small {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 140.44%;
    color: #ffc4c4;
  }
  p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140.44%;
    /* or 22px */

    display: flex;
    align-items: center;

    color: #ffffff;
  }
}
.border {
  border: 1px solid #DEDEDE !important;
}

h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 140.44%;
  color: #000000;
}
</style>
