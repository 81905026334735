import { render, staticRenderFns } from "./RequestPhysicalCard.vue?vue&type=template&id=00834800&scoped=true&"
import script from "./RequestPhysicalCard.vue?vue&type=script&lang=js&"
export * from "./RequestPhysicalCard.vue?vue&type=script&lang=js&"
import style0 from "./RequestPhysicalCard.vue?vue&type=style&index=0&id=00834800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00834800",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VDialog,VForm,VIcon,VSelect,VTextField})
